body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header nav {
  display: flex;
  justify-content: space-between;
  padding: 40px 5%;
  align-items: center;
  /* background: #18283B; */
}

header .logo img {
  color: #fff;
  font-size: 22px;
  height: 50px;
}

header ul {
  list-style: none;
}

header ul li {
  display: inline-block;
}

header ul li a {
  text-decoration: none;
  color: #8291a5;
  padding: 8px 20px;
  border-radius: 7px;
  transition: all 0.3s ease;
  font-weight: bold;
}

header ul li a:hover,
header ul li a.active {
  color: #fff;
  background: #2b85ff;
}


.burgerMenu {

}

#icon {
  /* background-color: #2b85ff; */
  padding: 5px 7px 3px 7px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

#icon svg {
  color: #fff;
  height: 28px;
  transition: all 0.3s ease;
}

#icon:hover {
  /* background: #fff; */
}

#icon:hover svg {
  color: #2b85ff;
}

#checkbox,
#icon {
  display: none;
}

.link i {
  padding-left: 7px;
}

.link:hover {
  text-decoration: underline;
  background-color: black;
}

.nice-select {
  background-color: transparent !important;
  border: none !important;
}

.nice-select .list {
  display: block;
}


@media screen and (max-width: 768px) {
  header nav {
    /* border-bottom: 1px solid #455468; */
    justify-content: center;
  }


  .selectLang {
    display: none;
  }

  header ul {
    position: absolute;
    width: 100%;
    height: calc(100vh - 71px);
    background-color: #0d0c1c;
    left: 0;
    top: 0;
    text-align: center;
    display: none;
    margin-top: 71px;
  }

  header ul li {
    display: block;
    padding: 10px 15px;
  }

  header ul li a {
    display: block;
    padding: 10px 0;
  }

  #icon {
    display: block;
  }

  #checkbox:checked ~ ul {
    display: block;
    margin-top: 150px;
  }
}
